import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import httpRequest from "../HttpRequestService";
import { formatDate3 } from "../function";

const Trx = (props) => {
  const [token] = useOutletContext();
  const [ref, setRef] = useState(props.dt.txId || '');

  const Confimer = async (type) => {
    if (ref.trim() === '') {
      return;
    }
    try {
      const response = await httpRequest(`${token}/trx/confirm`, { ref: ref, type, trxId: props.dt._id }, false);
      props.load();
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      //setErreur(error.response.data.message);
      props.load();
      console.log(error.response.data);
    }
  }

  const Rectiver = async () => {
    try {
      const response = await httpRequest(`${token}/trx/reactive`, { trxId: props.dt._id }, false);
      props.load();
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      //setErreur(error.response.data.message);
      props.load();
      console.log(error.response.data);
    }
  }

  const Cancel = async () => {
    try {
      const response = await httpRequest(`${token}/trx/cancel`, { trxId: props.dt._id }, false);
      props.load();
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      //setErreur(error.response.data.message);
      props.load();
      console.log(error.response.data);
    }
  }

  const copyToclipboard = () => {
    var input = document.createElement("input");
    input.value = props.dt.preUssd;
    document.body.appendChild(input);
    input.select();  
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  return (
    <tr>
      <td>{props.dt.public_id}</td>
      <td>{props.dt.apiName}</td>
      <td>{formatDate3(props.dt.createdAt)}</td>
      <td>{props.dt.type}</td>
      <td>
        {props.dt.amount} Ar <br />
        Numéro accépter : {props.dt.meta_data.find(m => m.key === 'phoneNumber').value}<br />
        {props.dt.txId ? 'Ref: ' + props.dt.txId : ''}
      </td>
      <td>{props.dt.status}</td>
      <td>{props.dt.orderId}</td>
      <td>
        {props.dt.passerelle.map(p => <>
          {`${p ? p.name : ''}`} <br /> {'- - - - - - - - - '} <br />
          {/* {p.operateur.map( o => <>{`${o.phone} (${o.name})`}<br/></> )} */}
        </>)}
      </td>
      {props.dt.type == 'retrait' && <td onClick={copyToclipboard}>
        code
      </td>}
      {props.dt.type == 'depot' && <td></td>}
      <td>
        {(props.dt.status === 'pending' || props.dt.status === 'checking' || (props.dt.status === 'completed' && !props.dt.txId)) && <Form.Group className="mb-3">
          <Form.Control placeholder="Référence" value={ref} onChange={(event) => setRef(event.target.value)} onKeyUp={(event) => setRef(event.target.value)} />
        </Form.Group>}
        {(props.dt.status === 'completed' && !props.dt.txId) && <Button onClick={() => Confimer('addRef')} variant="info" className="mt-1">Ajouter référence</Button>}
        {(props.dt.status === 'pending' || props.dt.status === 'checking') && <Button onClick={() => Confimer('confirm')} variant="success" className="mt-1">Confirmer</Button>}
        {(props.dt.status !== 'completed' && props.dt.status !== 'expired' && props.dt.status !== 'failed' && props.dt.status !== 'canceled') && <Button variant="danger" onClick={() => Cancel()} className="mt-1">Annuler</Button>}
        {(props.dt.status === 'expired') && <Button variant="danger" className="mt-1" onClick={Rectiver}>Réactiver</Button>}
      </td>
    </tr>
  );
}

export default Trx;